import React from 'react';
import PageTitle from '../common/title/PageTitle';

const About = () => {
  return (
    <div className="min-h-screen text-black py-8">
      <div className="container mx-auto">
        <PageTitle message={"ABOUT"} />

        <div className='flex flex-row mx-auto items-end overflow-hidden mt-12'>
          <div className="mt-10 text-black" style={{ width: '48rem', flexShrink: '0', zIndex: 2 }}>
            <p className='font-extrabold text-2xl'>STORY</p>
            <p className='font-extrabold text-5xl mt-12 leading-normal'>Bringing Dreams to Life with<br />AI-Driven Virtual Stars</p>
            <p className='font-bold mt-12 leading-10 w-1/2'>
              Alluci is an innovative AI-driven entertainment startup that emerged from a vision to revolutionize
              the world of virtual characters and AItubers.
            </p>
            <p className='font-bold mt-6 leading-10 w-1/2'>
              Founded on the principle of blending cutting-edge AI technology with creative storytelling,
              we set out to create a new generation of digital personalities that captivate and engage audiences across the globe.
            </p>
          </div>
          <div style={{ width: '52rem', flexShrink: '0', marginLeft: '-16rem' }} >
            <img src="/about/1.png" alt="home" style={{ width: '100%' }} />
          </div>
        </div>

        <div className='flex mx-auto items-end flex-row-reverse mt-40'>
          <div className="mt-10 text-black flex flex-col" style={{ width: '40rem', flexShrink: '0', zIndex: 2 }}>
            <p className='font-extrabold text-2xl'>MISSION</p>
            <p className='font-extrabold text-5xl mt-12 leading-normal'>Shaping the Future with AI<br />Connect and Inspire</p>
            <p className='font-bold mt-12 leading-10 w-3/4'>
              At Alluci, our mission is clear and ambitious: to become the world's most iconic AI character company
              and seamlessly integrate AI characters into human society.
            </p>
            <p className='font-bold mt-6 leading-10 w-3/4'>
              We strive to push the boundaries of what's
              possible in digital entertainment, creating meaningful connections between our AI characters and human
              audiences.
            </p>
          </div>
          <div style={{ width: '32rem', flexShrink: '0', marginRight: '8rem' }} >
            <img src="/about/2.png" alt="home" style={{ width: '100%' }} />
          </div>
        </div>
      </div>

      <div className="text-centermax-w-3xl space-y-8 my-40">
        <section className="bg-gray-400 py-20 text-white bg-cover bg-center" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/about/3.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        }}>
          <p className="text-3xl font-bold mb-4 text-center">Value</p>
          <p className="leading-relaxed text-white text-center text-xs">
            Multi-faceted, spanning various platforms and formats to bring our AI characters to life
          </p>
          <div className="grid grid-cols-5 gap-4 mt-12 px-16 divide-x-2 divide-white">
            <div className='pl-4'>
              <p className='text-2xl font-extrabold'>Interactive Games</p>
              <p className='text-sm mt-6'>We craft immersive experiences where our AI characters engage with audiences in interactive, virtual environments, blending the digital and real worlds seamlessly.</p>
            </div>
            <div className='pl-4'>
              <p className='text-2xl font-extrabold'>Digital Content</p>
              <p className='text-sm mt-6'>Our AI characters come to life through engaging videos, dynamic social media posts, and captivating digital art, connecting with audiences across various platforms.</p>
            </div>
            <div className='pl-4'>
              <p className='text-2xl font-extrabold'>Live Streaming</p>
              <p className='text-sm mt-6'>Our AItubers interact with audiences in real-time through live streams and chats, creating a unique, personal connection with viewers.</p>
            </div>
            <div className='pl-4'>
              <p className='text-2xl font-extrabold'>Merchandise</p>
              <p className='text-sm mt-6'>We offer physical products like merchandise and collectibles, allowing fans to connect with our AI characters in tangible, meaningful ways.</p>
            </div>
            <div className='pl-4'>
              <p className='text-2xl font-extrabold'>Events</p>
              <p className='text-sm mt-6'>We host virtual and real-world events focused on our AI characters, bringing fans together to celebrate and connect with these digital personalities.</p>
            </div>
          </div>
        </section>
      </div>

      <div className="container mx-auto my-40">
        <div className='grid grid-cols-3 gap-10 mx-auto'>
          <div className="mt-10 text-black" style={{ zIndex: 2 }}>
            <p className='font-extrabold text-5xl mt-12 leading-normal'>TECHNOLOGY</p>
            <p className='font-bold mt-12 leading-10'>
              At the heart of Alluci's innovation is our proprietary AI technology.
            </p>
            <p className='font-bold mt-6 leading-10'>
              We utilize advanced machine learning algorithms, natural language processing, and computer vision to create AI characters that
              are not just visually stunning, but also capable of natural, engaging interactions.
            </p>
            <p className='font-bold mt-6 leading-10'>
              Our technology
              allows for real-time animation, voice synthesis, and adaptive personality traits that evolve based
              on interactions with the audience.
            </p>
          </div>
          <div className='overflow-visible flex justify-center items-center'>
            <img src='/about/4.png' alt='4' className='max-w-none' style={{maxHeight: "40rem"}}></img>
          </div>
          <div className="mt-10 text-black flex flex-col" style={{ zIndex: 2 }}>
            <p className='font-extrabold text-5xl mt-12 leading-normal'>MIRACLE JOURNEY</p>
            <p className='font-bold mt-12 leading-10 w-3/4'>
              As we continue to grow and innovate, we invite you to be part of our exciting journey.
            </p>
            <p className='font-bold mt-6 leading-10 w-3/4'>
              Whether you're a fan, a creator, or a potential partner, there's a place for you in the Alluci community.
            </p>
            <p className='font-bold mt-6 leading-10 w-3/4'>
              Together, we're not just entertaining -- we're shaping the future of digital interaction and AI-driven entertainment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PageTitle from '../../common/title/PageTitle';
import './CharacterShowcase.css';

const CharacterCard = ({ index, chara }) => (
  <div className={`bg-white flex flex-col items-center`}>
    <div className="w-full aspect-square overflow-hidden mb-4 flex items-start justify-center">
      <img alt={index} src={`/home/${index}.png`} className='w-full flex-shrink-0' />
    </div>
    <p className={`text-2xl font-semibold ${chara.isComing ? "text-gray-400" : chara.textColor}`}>
      {chara.isComing ? `Coming Soon` : chara.name}
    </p>
  </div>
);

const CharacterShowcase = () => {
  const settings = {
    centerMode: true, // 启用中心模式
    centerPadding: "0", // 确保中央对齐
    slidesToShow: 3, // 显示的滑块数量
    focusOnSelect: true, // 点击滑块时切换到中央
    arrows: false, // 是否显示左右箭头
    infinite: true, // 无限循环
    speed: 500, // 切换速度
    autoplay: true, // 启用自动切换
    autoplaySpeed: 3000, // 自动切换的间隔时间 (毫秒)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const charas = [
    {
      isComing: false,
      name: "ALLUCI",
      textColor: "text-[#fdccb7]"
    },
    {
      isComing: true,
    },
    {
      isComing: true,
    },
    {
      isComing: true,
    }
  ];

  return (
    <section className="bg-white text-black py-16">
      <div className="container mx-auto px-4">
        <PageTitle message="Featured Characters" size="text-5xl" />
        <Slider {...settings} className='mt-24'>
          {charas.map((chara, index) => (
            <CharacterCard key={index + 1} index={index + 1} chara={chara} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default CharacterShowcase;
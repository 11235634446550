import React from 'react';

const Hero = () => (
  <section className="text-white py-20 w-full h-lvh bg-cover bg-center flex flex-col justify-center"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/home/hero/1.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'top',
    }}>
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-7xl font-bold mb-4">AI VIRTUAL CHARACTERS</h2>
      <p className="text-lg mb-8 max-w-2xl mx-auto">Unique AI IP characters in Alluci Studio</p>
    </div>
  </section>
);

export default Hero;
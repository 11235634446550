import React from 'react';
import Application from './app/Application';
import CharacterShowcase from './chara/CharacterShowcase';
import Events from './events/Events';
import Hero from './hero/Hero';

const Home = () => (
    <div>
        <Hero />
        <CharacterShowcase />
        {/* <LiveStream />
        <StoryMode /> */}
        <Events />
        {/* <CharacterCommunity /> */}
        <Application />
    </div>
);

export default Home;
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './Header.css'

const linkStyle = {
  "home": "underline-animation",
  "about": "underline-animation",
  "ai-intro": "underline-animation",
  "contact": "underline-animation",
};

const Header = () => {
  const location = useLocation();
  const routeName = location.pathname === '/' ? "home" : location.pathname;

  for (const key in linkStyle) {
    if (routeName.includes(key)) {
      linkStyle[key] = "underline-stable";
    } else {
      linkStyle[key] = "underline-animation";
    }
  }

  const primaryColor10 = getComputedStyle(document.documentElement).getPropertyValue('--primary-color-10').trim();

  return (
    <header className="bg-white text-black px-10">
      <div className="container mx-auto flex justify-between items-center">
        {/* <Link to="/" className="text-2xl font-black">ALLUCI</Link> */}
        <Link to="/">
          <img src="/logo.png" alt="home" style={{ height: '8rem' }} />
        </Link>
        <nav>
          <ul className="flex space-x-6 py-4 px-6 rounded-full border-2 font-extrabold text-xs" style={{borderColor: primaryColor10}}>
            <li><Link to="/" className={linkStyle["home"]}>HOME</Link></li>
            <li><Link to="/about" className={linkStyle["about"]}>ABOUT</Link></li>
            <li><Link to="/contact" className={linkStyle["contact"]}>CONTACT</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
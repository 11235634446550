import React, { useState } from 'react';
import PageTitle from '../common/title/PageTitle';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const subject = `Message from ${formData.name}`;
    const body = `Name: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0A%0D%0AMessage:%0D%0A${formData.message}`;

    window.location.href = `mailto:hong@alluci.studio?subject=${encodeURIComponent(subject)}&body=${body}`;

    // Clear the form
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <section className="bg-white text-black py-8">
      <div className="container mx-auto">
        <PageTitle message={"CONTACT"} />
        <p className='text-sm mt-6'>Feel free to contact us if you have questions about our services.</p>

        <div className="max-w-4xl mx-auto mt-16">
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className='flex flex-row justify-center items-center'>
              <div className='flex flex-row w-1/3 items-center'>
                <label htmlFor="name" className="text-base font-bold text-start">Name</label>
                <p className='text-xs font-bold bg-primary-color text-white px-1 py-0.5 ml-2'>required</p>
              </div>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-4 bg-gray-100"
              />
            </div>
            <div className='flex flex-row justify-center items-center mt-12'>
              <div className='flex flex-row w-1/3 items-center'>
                <label htmlFor="email" className="text-base font-bold text-start">Email Address</label>
                <p className='text-xs font-bold bg-primary-color text-white px-1 py-0.5 ml-2'>required</p>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder='name@example.com'
                required
                className="w-full p-4 bg-gray-100"
              />
            </div>
            <div className='flex flex-row justify-center items-center mt-12'>
              <div className='flex flex-row w-1/3 items-center'>
                <label htmlFor="email" className="text-baseext-base font-bold text-start">Message</label>
                {/* <p className='text-xs font-bold bg-primary-color text-white px-1 py-0.5 ml-2'>required</p> */}
              </div>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="w-full p-4 bg-gray-100"
              ></textarea>
            </div>
            <button type="Send with Above" className="w-fit self-center bg-primary-color text-white mt-40 px-10 py-4 text-lg font-bold hover:bg-white border-primary-color hover:text-primary-color border-2 transition duration-300">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
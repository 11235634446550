import React from 'react';
import PageTitle from '../../common/title/PageTitle';

const EventCard = ({ title, date, description }) => (
  <div className="flex flex-col">
    <div className="w-full aspect-video overflow-hidden mb-4 flex items-center justify-center rounded">
      <img alt={title} src={`/home/events/${title}.png`} className='w-full flex-shrink-0' />
    </div>
    <h3 className="text-xl font-semibold mb-2 mt-4">{title}</h3>
    <p className="text-primary-color mb-2">{date}</p>
    <p>{description}</p>
  </div>
);

const Events = () => (
  <section className="py-16 bg-white text-black">
    <div className="container mx-auto px-4">
      <PageTitle message="Upcoming Events" size="text-5xl" />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
        <EventCard
          title="Character Meet & Greet"
          date="October 15, 2024"
          description="Meet your favorite characters in person and get exclusive merchandise!"
        />
        <EventCard
          title="Story Writing Contest"
          date="October 1-31, 2024"
          description="Submit your own character stories and win amazing prizes!"
        />
        <EventCard
          title="Annual Fan Convention"
          date="October 20-22, 2024"
          description="Join us for panels, workshops, and special announcements at our biggest event of the year!"
        />
      </div>
    </div>
  </section>
);

export default Events;
import React from 'react';
import PageTitle from '../../common/title/PageTitle';

const Application = () => (
  <section className="py-16 bg-white text-black">
    <div className="container mx-auto px-4">
      <PageTitle message="Download Our App" size="text-5xl" />
      <div className="flex flex-col mt-16">
        <div className="text-center">
          <h3 className="text-3xl font-semibold mb-4">Experience on the Go</h3>
          <p className="mb-4 text-base">Take your favorite characters with you wherever you go. <br />Our mobile app provides seamless access to stories, live streams, and community features.</p>
          <div className="flex justify-center space-x-4 mt-12">
            <button className="bg-gray-800 text-white px-6 py-2 rounded hover:bg-gray-700 transition duration-300 flex flex-row items-center">
              <img alt='apple store' src='apple_logo_white.svg' className='h-4 mr-3'></img>App Store
            </button>
            <button className="bg-gray-800 text-white px-6 py-2 rounded hover:bg-gray-700 transition duration-300 flex flex-row items-center">
              <img alt='google play' src='android_logo.svg' className='h-4 mr-3'></img>Google Play
            </button>
          </div>
        </div>
        <div className="w-64 h-128 bg-gray-800 rounded-3xl"></div> {/* Placeholder for app screenshot */}
      </div>
    </div>
  </section>
);

export default Application;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './ui/AnimatedRoutes';
import Footer from './ui/common/footer/Footer';
import Header from './ui/common/header/Header';


const App = () => (
  <Router>
    <div className="min-h-screen flex flex-col bg-white text-white">
      <Header />
      <main className="flex-grow">
        <AnimatedRoutes />
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-white text-black py-8">
    <div className="container mx-auto">
      <div className="flex flex-row justify-between items-end">
        <p className="font-semibold text-xs mt-8">&copy; 2024 Alluci Studio Pte Ltd.</p>
        <div className='flex flex-col justify-center items-start'>
          <div className='flex flex-row items-center mt-8'>
            <img src='/logo_mono.png' style={{ width: 'auto', height: '3rem' }} alt="logo"></img>
            <Link key='bilibili' to='https://www.bilibili.com' >
              <img src='/bilibili_logo.svg' alt="bilibili" className='h-6 ml-8' />
            </Link>
            <Link key='youtube' to='https://www.youtube.com' >
              <img src='/youtube_logo.svg' alt="youtube" className='h-6 ml-6' />
            </Link>
            <Link key='x' to='https://x.com' >
              <img src='/x_logo.svg' alt="x" className='h-6 ml-6' />
            </Link>
          </div>
        </div>
        <div className="flex space-x-4">
          {[
            { name: 'Privacy Policy', },
            { name: 'Terms of Service', }
          ].map((item) => (
            <Link key={item.name} to={item.path} className="font-extrabold text-xs underline-animation">
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;